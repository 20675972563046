import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { ReactNode } from 'react';
import 'reflect-metadata';
import Layout from '../layout';
import styled from 'styled-components';
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {  Column, Container, Row, FlexProps } from '../styles/grids';

interface EventPageProps {
    children: ReactNode;
}

// TODO: Typify
interface EventData {
    contentfulEvents: {
        eventsList: any
    };
}

const Events = styled.div<FlexProps>`
  text-align: center;
  color: #F3867F;
  margin-bottom: 10vw;

  h1 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 48px;
      font-style: italic;
      margin: 5px;
      color: #F3867F;
  }

  h2 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 36px;
      margin: 5px;
      color: #F3867F;
  }

  h3 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 24px;
      margin: 5px;
      color: #F3867F;
  }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #F3867F;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }

  h1 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 30px;
      margin: 5px;
  }
`;

const EventsPage: React.FC<EventPageProps> = () => {

    const { contentfulEvents }: EventData = useStaticQuery(graphql`
        query {
            contentfulEvents{
                eventsList {
                    raw
                }
            }
        }
    `);

    const EventProps = {
        color: '#F3867F',
        margin: '10px',
        padding: '20px',
    };

    return (
        <Layout>
            <Container>
                <Row>
                    <Events>
                        {documentToReactComponents(
                            JSON.parse(contentfulEvents.eventsList.raw),
                            events_options()
                        )}
                    </Events>
                </Row>
            </Container>
        </Layout>
    );
};

/**
 * bio_options: Default rich text parsing options
 * for the bio page
 */
 const events_options = () => {
    const option_node = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
          return (
            <h3>{children}</h3>
          )
        },
        [INLINES.HYPERLINK]: (node: any, children: any) => {
            console.log(node)
            return (
              <StyledLink to={node.data.uri} target={'_blank'}>
                  {children}
              </StyledLink>
            )
          },
      },
    }
    return option_node
  }

export default EventsPage;
